<template>
  <Layout>
    <PageHeader
			:title="'Terms and Conditions'"
		/>
		<TermsAndConditions />
  </Layout>
</template>

<script>
import Layout from "../../layouts/public";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import TermsAndConditions from '@/components/terms-and-conditions';

export default {
	name: 'Terms',
  page: {
    title: "Terms and Conditions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
		Layout, 
		PageHeader,
		TermsAndConditions,	
	},
};
</script>